export const breadcrumbList = [
  {
    name: '商品',
    path: ''
  },
  {
    name: '客户列表',
    path: ''
  }
]
