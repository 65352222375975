<template>
  <div class="top-content">
    <el-button class="add-staff" type="primary" @click="handleAddStaffClick">
      <el-icon> <plus /> </el-icon>新增会员
    </el-button>
    <el-input
      class="search"
      prefix-icon="search"
      v-model="searchValue"
      placeholder="会员ID/姓名/手机号等"
    />
    <el-select v-model="value" class="m-2" placeholder="加入时间" size="large">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-select v-model="value" class="m-2" placeholder="会员等级" size="large">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-select v-model="value" class="m-2" placeholder="所属门店" size="large">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-select v-model="value" class="m-2" placeholder="客户来源" size="large">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <el-button class="add-staff" type="primary" @click="handleAddStaffClick"
      >筛选</el-button
    >
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  background-color: white;
  .search {
    max-width: 180px;
    min-width: 150px;
    margin: 0 10px;
  }
  &:deep(.el-select) {
    margin-right: 10px;
    .el-input {
      max-width: 160px;
    }
  }
}
</style>
