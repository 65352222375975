<template>
  <div class="member-list">
    <page-content
      :contentTableConfig="contentTableConfig"
      pageName="clientInfo"
      category="customer"
      :isButtonStyle="true"
    >
      <template #name="scope">
        <div class="name">
          <div class="user-avatar">
            <el-icon>
              <eleme-filled />
            </el-icon>
          </div>
          <div class="user-name">{{ scope.row.name.name }}</div>
          <div class="user-phone">{{ scope.row.name.phone }}</div>
        </div>
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini" @click="handleDetailClick(scope.row)">查看详情</el-button>
        </div>
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageContent from '@/components/page-content/src/page-content.vue'
import { contentTableConfig } from './config'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {
    const router = useRouter()

    const handleDetailClick = () => {
        router.push('/main/customer/detail')
    }
    return {
      contentTableConfig,
      handleDetailClick
    }

  }
})
</script>

<style scoped lang="less">
.member-list {
  margin-top: 20px;
  .name {
    .user-avatar {
      i {
        font-size: 40px;
      }
    }
  }
}
</style>







