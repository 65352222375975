export const contentTableConfig = {
  propList: [
    { prop: 'name', label: '姓名/手机', minWidth: '150', slotName: 'name' },
    { prop: 'card_num', label: '持卡数量', minWidth: '150' },
    {
      prop: 'source',
      label: '来源',
      minWidth: '120',
      slotName: 'department'
    },

    {
      prop: 'registration_time',
      label: '注册时间',
      minWidth: '120',
      slotName: 'entry_time'
    },
    {
      prop: 'last_arrival_time',
      label: '最近到店时间',
      minWidth: '120',
      slotName: 'entry_time'
    },
    {
      prop: 'shop',
      label: '归属门店',
      minWidth: '120',
      slotName: 'entry_time'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
